<template>
	<el-row style="position: absolute; top: 5px;">
		<el-col>
			<el-input style="opacity: 0.8; height: 28px;" size="small" v-model="old_password" placeholder="旧密码" clearable maxlength="16" minlength="6" show-word-limit/>
			<el-input size="small" v-model="password" placeholder="新密码" style="margin-top:5px;opacity: 0.8;height: 28px;"
				clearable show-password maxlength="16" minlength="6" show-word-limit/>
			<el-input size="small" v-model="password2" placeholder="再次输入密码"
				style="margin-top:5px;opacity: 0.8;height: 28px;" clearable show-password maxlength="16" minlength="6" show-word-limit/>
		</el-col>
	</el-row>

	<el-row class="ckbtn">
		<el-col>
			<el-button type="danger" style="margin-top: 6px; width: 100%; height: 50px;" @click="修改密码" :disabled="按下按钮">修改密码</el-button>
		</el-col>
	</el-row>
</template>

<script>
	import axios from 'axios'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		name: 'gaimi',
		setup() {

			const store = useStore()
			const localport = computed(() => store.state.localport)
			const old_password = ref("")
			const password = ref("")
			const password2 = ref("")
			const 按下按钮 = ref(false)

			const 修改密码 = async () => {
				try {
					if (old_password.value.length <= 6) {
						ElMessage.error("密码必须大于六位");
						return; // 停止执行
					}
					if (password.value !== password2.value) {
						ElMessage.error("两次输入的密码不一致");
						return; // 停止执行
					}
					if (old_password.value === "" || password.value === "" || password2.value === "") {
						ElMessage.error("密码不能为空");
						return; // 停止执行
					}

					按下按钮.value = true
					// 进行注册请求
					const response = await axios.post('http://localhost:' + localport.value +
						'/api/v1/async_gaimi', {
							"old_password": old_password.value,
							"password": password.value
						});

					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200 && response.data.code === 200) {
						// 注册成功的逻辑
						ElMessage.success(response.data.msg);
					} else {
						// 注册失败的逻辑
						ElMessage.error(response.data.msg);
					}
				} catch (error) {
					// 错误处理逻辑
				} finally {
					// 最终处理逻辑
					按下按钮.value = false
				}
			};

			return {
				old_password,
				password,
				password2,
				修改密码,
				按下按钮,
			}
		}
	});
</script>

<style scoped>
	.el-button--danger {
		background-color: darkred;
		border-color: darkred;
	}

	.ckbtn {
		width: 100%;
		position: absolute;
		top: 100px;
	}
</style>