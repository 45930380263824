<template>
	<el-row style="position: absolute; top: 20px;" v-show="is_login_A">
		<el-col>
			<el-input style="opacity: 0.8; height: 28px;" size="small" v-model="computedUsername" placeholder="用户名" clearable maxlength="16" minlength="6" show-word-limit/>
			<el-input size="small" v-model="password" placeholder="密码" style="margin-top:5px;opacity: 0.8;height: 28px;"
				clearable show-password maxlength="16" minlength="6" show-word-limit/>
			<el-checkbox size="small" style="color: white;" v-model="记住密码" >记住密码</el-checkbox>
		</el-col>
	</el-row>

	<el-row class="ckbtn" v-show="is_login_A">
		<el-col>
			<el-button type="danger" style="margin-top: 6px; width: 100%; height: 50px;" @click="登陆A" :disabled="按下按钮">登
				录</el-button>
		</el-col>
	</el-row>

	<el-row v-show="!is_login_A" style="width: 100%; margin-top:65px;">
		<el-col>
			<el-input style="opacity: 0.8; height: 28px;" size="small" disabled :placeholder="vipExpDate" clearable />
		</el-col>
	</el-row>

	<el-row class="ckbtn" v-show="!is_login_A">
		<el-col>
			<el-button type="warning" style="margin-top: 6px; width: 100%; height: 50px;"
				:disabled='login_btn_B' @click="进入软件">进入软件</el-button>
		</el-col>
	</el-row>

</template>

<script>
	import axios from 'axios'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		name: 'login',
		setup() {
			const store = useStore()
			const localport = computed(() => store.state.localport)
			const is_login_A = computed(() => store.state.is_login_A)
			
			const mynotice = computed(() => store.state.mynotice)
			const username_global = computed(() => store.state.username_global)
			const computedUsername = computed({
			      get: () => store.state.username_global,
			      set: (value) => {
			        // 在这里设置 computedUsername 的值，这将影响到 store.state.username_global
			        store.commit('set_username_global', value) // 假设使用 Vuex 来管理状态
			      },
			    })
				
			const password = ref("")
			const 记住密码 = ref(false)
			const vipExpDate = ref("")
			const login_btn_B = ref(true)
			const 按下按钮 = ref(false)

			const 获取数据 = async () => {
				try {
					const response = await axios.get('http://localhost:' + localport.value +
						'/api/v1/async_init');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200) {
						store.commit("set_mynotice", response.data.new_content)
						store.commit("set_username_global", response.data.username)
						// ElMessage.success(response.data.username);
						// username.value = response.data.username
						password.value = response.data.password
						if (response.data.jzpwd === "true") {
							记住密码.value = true
						}


					} else {

					}
				} catch (error) {

				} finally {

				}
			};
			
			const 进入软件 = async () => {
				try {
					const response = await axios.get('http://localhost:' + localport.value +
						'/api/v1/async_gorun');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200 && response.data.code === 200) {


					} else {
						ElMessage.error(response.data.msg);
					}
				} catch (error) {
			
				} finally {
			
				}
			};


			const 登陆A = async () => {
				try {
					按下按钮.value = true
					// 在 API 调用前延迟 5 秒
					// await new Promise(resolve => setTimeout(resolve, 1000)); // 5000 毫秒即 5 秒

					const response = await axios.post('http://localhost:' + localport.value +
						'/api/v1/async_login_A', {
							"username": username_global.value,
							"password": password.value,
							"jzpwd": 记住密码.value
						});

					// 根据接口返回的状态码是否为 200，修改变量 unturned_path 的值
					if (response.status === 200 && response.data.code === 200) {
						ElMessage.success('登陆成功');
						ElMessage.success('到期时间:' + response.data.vipExpDate);
						vipExpDate.value = '到期时间:' + response.data.vipExpDate
						store.commit("set_is_login_A", false)
						login_btn_B.value = false

					} else if (response.status === 200 && response.data.code === -5) {
						ElMessage.warning('登陆成功');
						ElMessage.warning('该账户已到期:' + response.data.vipExpDate);
						vipExpDate.value = '已到期:' + response.data.vipExpDate
						store.commit("set_is_login_A", false)
						login_btn_B.value = true

					} else {
						ElMessage.error(response.data.msg);
					}
				} catch (error) {
					console.log(error)

				} finally {
					按下按钮.value = false
				}

			};


			onMounted(获取数据)

			return {
				mynotice,
				username_global,
				password,
				登陆A,
				记住密码,
				is_login_A,
				vipExpDate,
				login_btn_B,
				按下按钮,
				进入软件,
				computedUsername
			}
		}
	});
</script>

<style scoped>
	.notice {
		height: 124px;
		max-height: 124px;
		/* 设置最大高度为200像素，可以根据需要调整 */
		overflow-y: auto;
		font-size: 12px;
		/* font-family: 'Noto Sans SC', sans-serif; */
		font-weight: 500;
		/* 设置粗体样式 */
		margin-top: 26px;
		color: white;

	}

	.el-button--danger {
		background-color: darkred;
		border-color: darkred;
	}

	.ckbtn {
		width: 100%;
		position: absolute;
		top: 100px;
	}
</style>