
	import { computed, ref } from 'vue'
	import { defineComponent, onMounted } from 'vue';
	import { Timer } from '@element-plus/icons-vue'
	import { useStore } from 'vuex'
	import axios from 'axios'
	import {
		ElMessage
	} from 'element-plus';


	export default defineComponent({
		name: 'App',
		components: {

		},

		setup() {
			const store = useStore()
			const localport = computed(() => store.state.localport)

			const title = ref("R.Z.A.I.内.部")
			const mynotice = computed(() => store.state.mynotice)

			// 重设端口号
			// 获取指定参数的值
			const searchParams = new URLSearchParams(window.location.search);
			const new_localport = ref<number>(parseInt(searchParams.get('new_localport')!) || localport.value);
			// console.log(new_localport)
			// 同步到vuex
			store.commit("setLocalPort", new_localport)
			// console.log(computed(() => store.state.localport))
			const is_login_A = computed(() => store.state.is_login_A)
			
			const 关闭程序 = async () => {
				try {
					
					const response = await axios.get('http://localhost:' + localport.value + '/api/v1/async_kill');
					// console.log(response)
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200) {

					} else {

					}
				} catch (error) {

				} finally {

				}
			};
			
			const 充值按钮 = () => {
				if(is_login_A.value === true){
					ElMessage.error("请先登录");
				}
			}

			// 将 ref 暴露给模板
			return {
				title,
				关闭程序,
				充值按钮,
				is_login_A,
				mynotice
			}
		}

	});
