<template>
	<el-row style="width: 100%; position: absolute; top: 70px;">
		<el-col style="">
			<el-input style="opacity: 0.8; height: 28px;" size="small" v-model="kami" placeholder="卡密" clearable />
		</el-col>
	</el-row>

	<el-row  class="ckbtn">
		<el-col>
			<el-button type="danger" style="margin-top: 6px; width: 100%; height: 50px;" @click="充值">充 值</el-button>
		</el-col>
	</el-row>
</template>

<script>
	import axios from 'axios'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus';
	import {
		computed,
		ref
	} from 'vue'
	import {
		defineComponent,
		onMounted
	} from 'vue';
	export default defineComponent({
		name: 'chongzhi',
		setup() {

			const store = useStore()
			const localport = computed(() => store.state.localport)
			const kami = ref("")
			const 充值 = async () => {
				try {

					// 进行注册请求
					const response = await axios.post('http://localhost:' + localport.value +'/api/v1/async_chongzhi', {"kami": kami.value});
				
					// 根据接口返回的状态码是否为200，修改变量abc的值
					if (response.status === 200 && response.data.code === 200) {
						// 注册成功的逻辑
						ElMessage.success(response.data.msg);
					} else {
						// 注册失败的逻辑
						ElMessage.error(response.data.msg);
					}
				} catch (error) {
					// 错误处理逻辑
				} finally {
					// 最终处理逻辑
				}
			};

			return {
				kami,
				充值,
			}
		}
	});
</script>

<style scoped>
	.el-button--danger {
		background-color: darkred;
		border-color: darkred;
	}
	
	.ckbtn {
		width: 100%;
		position: absolute;
		top: 100px;
	}
</style>