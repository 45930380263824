import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8163c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "notice",
                      innerHTML: _ctx.mynotice
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { style: {"height":"165px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component,route }) => [
                    (_openBlock(), _createBlock(_KeepAlive, null, [
                      (_ctx.$route.meta.keepAlive)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                            key: route.name
                          }))
                        : _createCommentVNode("", true)
                    ], 1024)),
                    (!_ctx.$route.meta.keepAlive)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                          key: route.name
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { style: {"margin-top":"0px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, { class: "links" }, {
                          default: _withCtx(() => [
                            _createTextVNode("登陆")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/register" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, { class: "links" }, {
                          default: _withCtx(() => [
                            _createTextVNode("注册")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    (_ctx.is_login_A)
                      ? (_openBlock(), _createBlock(_component_el_link, {
                          key: 0,
                          class: "links",
                          onClick: _ctx.充值按钮
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("充值")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _withDirectives(_createVNode(_component_router_link, { to: "/chongzhi" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, { class: "links" }, {
                          default: _withCtx(() => [
                            _createTextVNode("充值")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, !_ctx.is_login_A]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, { to: "/huanbang" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, { class: "links" }, {
                          default: _withCtx(() => [
                            _createTextVNode("解绑")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    (_ctx.is_login_A)
                      ? (_openBlock(), _createBlock(_component_el_link, {
                          key: 0,
                          class: "links",
                          onClick: _ctx.充值按钮
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("改密")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _withDirectives(_createVNode(_component_router_link, { to: "/gaimi" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, { class: "links" }, {
                          default: _withCtx(() => [
                            _createTextVNode("改密")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, !_ctx.is_login_A]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  span: 3,
                  offset: 6
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_link, {
                      class: "links",
                      onClick: _ctx.关闭程序
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("关闭")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}