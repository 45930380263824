import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory  } from 'vue-router'
import Login from '@/components/login.vue'
import Index from '@/components/Index.vue'
import Register from '@/components/register.vue'
import Chongzhi from '@/components/chongzhi.vue'
import Huanbang from '@/components/huanbang.vue'
import Gaimi from '@/components/gaimi.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: 'login',
    component: Login,
	meta: {
	        keepAlive:true
	    },
  },
  {
    path: "/register",
    name: 'register',
    component: Register,
	meta: {
	        keepAlive:true
	    },
  },
  {
    path: "/chongzhi",
    name: 'chongzhi',
    component: Chongzhi,
  	meta: {
  	        keepAlive:true
  	    },
  },
  {
    path: "/huanbang",
    name: 'huanbang',
    component: Huanbang,
  	meta: {
  	        keepAlive:true
  	    },
  },
  {
    path: "/gaimi",
    name: 'gaimi',
    component: Gaimi,
  	meta: {
  	        keepAlive:true
  	    },
  },
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
