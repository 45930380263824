import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const rootapp = createApp(App)
rootapp.use(store)
rootapp.use(ElementPlus)
rootapp.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  rootapp.component(key, component)
}

rootapp.mount('#app')
