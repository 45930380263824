import { createStore } from 'vuex'

export default createStore({
	state: {
		"localport": 8899,
		"is_login_A":true,
		"mynotice":"获取失败",
		"username_global":" "
	},
	getters: {
	},
	mutations: {
		setLocalPort(state, payload) {
			state.localport = payload;
		},
		set_is_login_A(state, path) {
			state.is_login_A = path;
		},
		set_mynotice(state, payload) {
			state.mynotice = payload;
		},
		set_username_global(state, payload) {
			state.username_global = payload;
		},
	},
	actions: {
	},
	modules: {
	}
})